
import { computed, reactive, ref, defineComponent } from "vue"
import { useStore } from "vuex"
import { setup, Vue } from "vue-class-component"
import { usePerms } from "../logic/usePerms"
import axios from "axios"

export default defineComponent({
  components: {},
  setup() {
    /* eslint-disable */
    const store = useStore()
    const { hasPerm, checkToken } = usePerms()

    const navselect = ref("users")

    const token = computed(() => store.getters["auth/token"])
    const host = computed(() => store.getters["auth/host"])

    const newname = ref("")
    const newemail = ref("")
    const newpassword = ref("")

    /*     const emailvalid = (email: string)=>{
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase())}
    
      const namevalid = (name: string)=>{      
          const re = /^[a-zA-Z0-9]{5,21}$/;
          return re.test(String(name).toLowerCase()) }
          
    
      const passwordvalid = (pass: string)=>{      
          const re = /^[a-zA-Z0-9]{9,21}$/;
          return re.test(String(pass).toLowerCase()) } */

    const newpar: any = reactive({ name: "", value: "" })

    const setnewpar = async (name: string, perm: any) => {
      newpar.name = name
      if (perm == true) {
        newpar.value = "true"
      }

      const { value, start, expired } = perm
      newpar.start = null
      newpar.expired = null
      if (value) {
        newpar.value = value
      }
      if (start) {
        newpar.start = new Date(start).toISOString()
      }
      if (expired) {
        newpar.expired = expired
      }
    }

    // User functions
    const users = ref([])
    const getUsers = async () => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: host.value,
          url: "/users",
          data: {},
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            users.value = resp.data.users
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    const user = ref()
    const getUser = async (uuid: string) => {
      await checkToken()
      if (hasPerm("system.admin")) {
        axios({
          baseURL: host.value,
          url: "/users/" + uuid,
          data: {},
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            user.value = resp.data.user
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    const addUser = async () => {
      //validate formdata

      await checkToken()
      if (hasPerm("system.admin")) {
        axios({
          baseURL: host.value,
          url: "/users",
          data: {
            name: newname.value,
            email: newemail.value,
            password: newpassword.value,
          },
          method: "post",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            users.value = resp.data.users
            user.value = resp.data.user
          })
          .catch((err) => {
            console.log(err)
          })
      }
      newname.value = ""
      newemail.value = ""
      newpassword.value = ""
    }

    const editUser = async (uuid: string, edit: any) => {
      await checkToken()
      if (hasPerm("system.admin")) {
        if (edit.value) {
          if (edit.value == "true") {
            edit.value = true
          }
          const { value, start, expired } = edit.value
          if (value) {
            if (value == "true") {
              edit.value.value = true
            }
          }
          if (value && start && expired) {
            if (value == "true" && expired == null && start == null) {
              edit.value = true
            }
          }
        }

        axios({
          baseURL: host.value,
          url: "/users/" + uuid,
          data: {
            patch: [edit],
          },
          method: "patch",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            users.value = resp.data.users
            user.value = resp.data.user
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    const deleteUser = async (uuid: string) => {
      await checkToken()
      if (hasPerm("system.admin")) {
        console.log(uuid)
        axios({
          baseURL: host.value,
          url: "/users/" + uuid,
          data: {},
          method: "delete",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            user.value = undefined
            users.value = resp.data.users
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    // Company functions
    const companys = ref([])
    const getCompanys = async () => {
      await checkToken()

      if (hasPerm("system.admin")) {
        axios({
          baseURL: host.value,
          url: "/companys",
          data: {},
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            companys.value = resp.data.companys
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    const company = ref()
    const getCompany = async (uuid: string) => {
      await checkToken()
      if (hasPerm("system.admin")) {
        axios({
          baseURL: host.value,
          url: "/companys/" + uuid,
          data: {},
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            company.value = resp.data.company
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    const addCompany = async () => {
      //validate formdata

      await checkToken()
      if (hasPerm("system.admin")) {
        axios({
          baseURL: host.value,
          url: "/companys",
          data: {
            name: newname.value,
          },
          method: "post",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            companys.value = resp.data.companys
            company.value = resp.data.company
          })
          .catch((err) => {
            console.log(err)
          })
      }
      newname.value = ""
    }

    const companyProps = computed(() => {
      const cprops = []
      const booleans = ["active", "ehf"]
      const props = [
        "name",
        "domain",
        "active",
        "org",
        "address",
        "zip",
        "city",
        "country",
        "ehf",
        "email",
      ]

      for (let i = 0; i < props.length; i++) {
        const prop = props[i]
        let value = company.value[prop] || ""
        cprops.push({
          name: prop,
          value: value,
          boolean: booleans.includes(prop),
        })
      }
      return cprops
    })

    const editCompany = async (uuid: string, edit: any) => {
      await checkToken()
      console.log(edit.value)
      if (hasPerm("system.admin")) {
        axios({
          baseURL: host.value,
          url: "/companys/" + uuid,
          data: {
            patch: [edit],
          },
          method: "patch",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            companys.value = resp.data.companys
            company.value = resp.data.company
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    const deleteCompany = async (uuid: string) => {
      await checkToken()
      if (hasPerm("system.admin")) {
        axios({
          baseURL: host.value,
          url: "/companys/" + uuid,
          data: {},
          method: "delete",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            company.value = undefined
            companys.value = resp.data.companys
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    getUsers()

    return {
      islogedIn: computed(() => store.getters["auth/isLogedIn"]),
      username: computed(() => store.getters["auth/username"]),
      hasPerm,
      navselect,
      newname,
      newemail,
      newpassword,
      newpar,
      setnewpar,
      users,
      user,
      getUsers,
      getUser,
      addUser,
      editUser,
      deleteUser,
      companys,
      company,
      getCompanys,
      getCompany,
      addCompany,
      editCompany,
      deleteCompany,
      companyProps,
    }
  },
})
